import React from "react";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ROJFeatureBox from "../FeatureBox";

const ROJAboutSection = props => {
  return (
    <>
      <section id="features" className="featureSection">
        <div className="content">
          <div className="featureMissionStatement">
            <Trans i18nKey="featureMissionStatement">
              All Features Carefully Designed; <br />
              To Boost Sellers Revenue and Eliminate Buyers Stress
            </Trans>
          </div>
          <div className="hrContainer">
            <hr />
          </div>
          <Row className="featureBoxRow">
            <Col>
              {/* HIDE WHEN MOBILE DEVICE */}
              <div className="hideOnMobile">
                <ROJFeatureBox
                  title={props.t("buy")}
                  description={props.t("buyFeatureDesc")}
                  iconURL="/icons/buy.svg"
                />
                <ROJFeatureBox
                  title={props.t("sell")}
                  description={props.t("sellFeatureDesc")}
                  iconURL="/icons/sell.svg"
                />
              </div>
              {/* SHOW WHEN MOBILE DEVICE */}
              <div className="showOnMobile">
                <ROJFeatureBox
                  title={props.t("buy")}
                  description={props.t("buyFeatureDesc")}
                  iconURL="/icons/buy.svg"
                  isPositionRight={true}
                />
                <ROJFeatureBox
                  title={props.t("sell")}
                  description={props.t("sellFeatureDesc")}
                  iconURL="/icons/sell.svg"
                  isPositionRight={true}
                />
              </div>
            </Col>
            <Col lg={3} md={4}>
              <div className="phoneBox"></div>
            </Col>
            <Col>
              <div>
                <ROJFeatureBox
                  title={props.t("addFunds")}
                  description={props.t("addFundsFeatureDesc")}
                  iconURL="/icons/add_funds.svg"
                  isPositionRight={true}
                />
                <ROJFeatureBox
                  title={props.t("withdraw")}
                  description={props.t("withdrawFeatureDesc")}
                  iconURL="/icons/withdraw.svg"
                  isPositionRight={true}
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

ROJAboutSection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(ROJAboutSection);
