import React from "react";
import { Trans, withTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PropTypes from "prop-types";

const images = [
  "/images/phone/onboarding-product.svg",
  "/images/phone/onboarding-service.svg",
  "/images/phone/home-screen.svg",
  "/images/phone/products.svg",
  "/images/phone/buy-amount.svg",
  "/images/phone/buy-pin.svg",
  "/images/phone/buy-scan.svg",
  "/images/phone/roojaa-code.svg",
  "/images/phone/buy-success.svg",
  "/images/phone/wallet.svg",
  "/images/phone/transaction-purchases.svg",
  "/images/phone/transaction-purchases-details.svg",
  "/images/phone/transaction-funds.svg",
  "/images/phone/transaction-withdrawals.svg",
  "/images/phone/transaction-funding-bank-details.svg",
  "/images/phone/transaction-funding-success.svg",
  "/images/phone/settings.svg",
];

const ROJScreenshotSection = props => {
  let screenshots = images.map((image, index) => {
    return (
      <div
        key={`screenshot${index}`}
        className="phoneBox"
        style={{ backgroundImage: `url('${image}')` }}
      ></div>
    );
  });

  return (
    <>
      <section id="screenshots" className="screenshotSection">
        <div className="content">
          <div className="screenshotTitle">
            <Trans i18nKey="screenshotTitle">
              You were in Our Thoughts All Through The <br />
              Process. You will Always Be!
            </Trans>
          </div>
          <div className="hrContainer">
            <hr />
          </div>
          <div className="screenshotBoxRow">
            <OwlCarousel
              // className="owl-theme"
              loop
              margin={12}
              center={true}
              autoWidth={true}
              nav
              autoplay={true}
              autoplayTimeout={3000}
              smartSpeed={4000}
              fluidSpeed={3000}
              dotsSpeed={false}
              // navText={[
              //   "<i class='lni lni-chevron-left'></i>",
              //   "<i class='lni lni-chevron-right'></i>"
              // ]}
              responsive={{
                0: {
                  nav: false
                },
                1025: {
                  nav: true
                }
              }}
            >
              {screenshots}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
};

ROJScreenshotSection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(ROJScreenshotSection);
