import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./fontawesome/css/fontawesome.min.css";
import "./fontawesome/css/regular.min.css";
import "./fontawesome/css/solid.min.css";
import "./App.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <App history={history} />
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
