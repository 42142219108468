import React from "react";
import { ROJMenuButton } from "./Buttons";
import "./styles/Menu.css";

const iconURLS = [
  "/icons/home.svg",
  "/icons/info.svg",
  "/icons/feature.svg",
  "/icons/smartphone.svg",
  "/icons/download.svg",
  "/icons/phone-call.svg"
];

const links = [
  "#home",
  "#about",
  "#features",
  "#screenshots",
  "#download",
  "#contact"
];

const ROJMenu = () => {
  var menuButtons = iconURLS.map((url, index) => (
    <a key={`menuButton${index}`} href={links[index]} rel="noopener noreferrer">
      <ROJMenuButton iconURL={url} />
    </a>
  ));
  return (
    <>
      <div className="menuContainer">{menuButtons}</div>
    </>
  );
};

export default ROJMenu;
