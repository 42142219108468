import React from "react";
import PropTypes from "prop-types";
import "./styles/Buttons.css";

export const ROJDownloadButton = props => {
  return (
    <>
      <a href={props.link}>
        <div
          onClick={props.handleClick}
          className="downloadButton"
          style={{ backgroundImage: `url('${props.iconURL}')` }}
        ></div>
        {/* <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/> */}
      </a>
      {/* <div className="downloadButton">
        <div className="textContainer">
          <span className="download">{props.title}</span>
          <div className="storeText">{props.subtitle}</div>
        </div>
        <div
          className="storeIcon"
          style={{ backgroundImage: `url('${props.iconURL}')` }}
        ></div>
      </div> */}
    </>
  );
};

ROJDownloadButton.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  iconURL: PropTypes.string,
  link: PropTypes.string,
  handleClick: PropTypes.func
};

export const ROJMenuButton = props => {
  return (
    <>
      <div className="menuButtonContainer">
        <div
          className="icon"
          style={{ backgroundImage: `url('${props.iconURL}')` }}
        ></div>
      </div>
    </>
  );
};

ROJMenuButton.propTypes = {
  iconURL: PropTypes.string
};
