import React, { Suspense } from "react";
import PropTypes from "prop-types";
import ROJFooterSection from "../components/FooterSection";
import ROJRewardTermsSection from "../components/reward_terms/TermsSection";
import "../i18n";

const ROJRewardTerms = props => (
  <>
    <Suspense fallback={<div></div>}>
      {/* <HCHeader/> */}
      <div className="privacyContent">
        <ROJRewardTermsSection />
        <ROJFooterSection />
      </div>
      {/* <HCFooter/> */}
    </Suspense>
  </>
);

ROJRewardTerms.propTypes = {
  t: PropTypes.func
};

export default ROJRewardTerms;
