import React from "react";
import PropTypes from "prop-types";
import "./styles/InfoBox.css";

const ROJInfoBox = props => {
  return (
    <>
      <div className="infoBox">
        <div className="infoIconBox">
          <div
            className="infoIcon"
            style={{ backgroundImage: `url('${props.iconURL}')` }}
          ></div>
        </div>
        <div className="infoTitle">{props.title}</div>
        <div className="infoText">{props.text}</div>
        {/* <div className="infoLink">
          <a href="#seemore">{props.linkText}</a>
        </div> */}
      </div>
    </>
  );
};

ROJInfoBox.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  linkText: PropTypes.string,
  iconURL: PropTypes.string
};

export default ROJInfoBox;
