import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ROJParagraphBox from "../ParagraphBox";
import ROJLogo from "../Logo";

const privacyTranslationData = [
  {
    title: "privacyPolicyPersonalIdentificationInfo",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "privacyPolicyPersonalIdentificationInfo1.1",
          subListItems: [
            {
              text: "privacyPolicyPersonalIdentificationInfo1.1.1"
            },
            {
              text: "privacyPolicyPersonalIdentificationInfo1.1.2"
            },
            {
              text: "privacyPolicyPersonalIdentificationInfo1.1.3"
            },
            {
              text: "privacyPolicyPersonalIdentificationInfo1.1.4"
            },
            {
              text: "privacyPolicyPersonalIdentificationInfo1.1.5"
            }
          ]
        }
      },
      {
        item: {
          text: "privacyPolicyPersonalIdentificationInfo1.2",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "privacyPolicyNonPersonalIdentificationInfo",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "privacyPolicyNonPersonalIdentificationInfo2.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyNonPersonalIdentificationInfo2.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyNonPersonalIdentificationInfo2.3",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "privacyPolicyPaymentInfo",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "privacyPolicyPaymentInfo3.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyPaymentInfo3.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyPaymentInfo3.3",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyPaymentInfo3.4",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "privacyPolicyThirdPartyProcessor",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "privacyPolicyThirdPartyProcessor4.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyThirdPartyProcessor4.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyThirdPartyProcessor4.3",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyThirdPartyProcessor4.4",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyThirdPartyProcessor4.5",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyThirdPartyProcessor4.6",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "privacyPolicyFundingInfo",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "privacyPolicyFundingInfo5.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyFundingInfo5.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyFundingInfo5.3",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyFundingInfo5.4",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "privacyPolicyWithdrawalInfo",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "privacyPolicyWithdrawalInfo6.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyWithdrawalInfo6.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyWithdrawalInfo6.3",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "privacyPolicyHowWeUseInfo",
    subtitle: "privacyPolicyHowWeUseInfoSubtitle",
    listItems: [
      {
        item: {
          text: "privacyPolicyHowWeUseInfo7.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyHowWeUseInfo7.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyHowWeUseInfo7.3",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyHowWeUseInfo7.4",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "privacyPolicyRecordKeeping",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "privacyPolicyRecordKeeping8.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyRecordKeeping8.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "privacyPolicyRecordKeeping8.3",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "privacyPolicyChangesToOurPrivacyPolicy",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "privacyPolicyChangesToOurPrivacyPolicy9.1",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "privacyPolicyContactUs",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "privacyPolicyContactUs10.1",
          subListItems: []
        }
      }
    ]
  }
];

const ROJPrivacyPolicySection = props => {
  var privacyItems = privacyTranslationData.map((data, titleIndex) => {
    titleIndex = titleIndex + 1;
    return (
      <ROJParagraphBox
        key={`${titleIndex}. ROJParagraphBox`}
        titleListNumber={`${titleIndex}. `}
        title={props.t(data.title)}
        subtitle={props.t(data.subtitle)}
      >
        <ul key={`${titleIndex}privacydatalist`}>
          {data.listItems.map((item, listItemsIndex) => {
            listItemsIndex = listItemsIndex + 1;
            return (
              <li key={`${listItemsIndex}privacyitemlist`}>
                <div>
                  <span className="numberText">
                    {titleIndex + "." + listItemsIndex}
                  </span>
                  {props.t(`${item.item.text}`)}
                  <div>
                    <ul key={`${listItemsIndex}privacysubitemlist`}>
                      {item.item.subListItems.map((subitem, index) => {
                        index = index + 1;
                        return (
                          <li key={`${index}privacysubsubitemlist`}>
                            <div>
                              <span className="numberText">{`${titleIndex}.${listItemsIndex}.${index}`}</span>
                              {props.t(`${subitem.text}`)}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </ROJParagraphBox>
    );
  });

  return (
    <>
      <ROJLogo />

      <div className="content">
        <div className="pageTitle">{props.t("privacyPolicy")}</div>
        <ROJParagraphBox>
          {props.t("privacyPolicyEffectiveDate")}
        </ROJParagraphBox>
        <ROJParagraphBox>{props.t("privacyPolicyIntro")}</ROJParagraphBox>
        {privacyItems}
        <ROJParagraphBox>
          {props.t("privacyPolicayLastUpdated")}
        </ROJParagraphBox>
      </div>
    </>
  );
};

ROJPrivacyPolicySection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(ROJPrivacyPolicySection);
