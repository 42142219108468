import React from "react";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TextLoop from "react-text-loop";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ROJDownloadButton } from "../Buttons";
import ROJLogo from "../Logo";
import { analytics } from "../../App";
import { logEvent } from "firebase/analytics";

const buyData = [
  {
    title: "food"
  },
  {
    title: "transport"
  },
  {
    title: "grocery_plural"
  },
  {
    title: "books"
  },
  {
    title: "gifts"
  },
  {
    title: "saloon",
    className: "saloon"
  }
];

const ROJHomeSection = props => {
  var buyLoops = buyData.map((data, index) => (
    <span key={`buyData${index}`} className="productTypeItem">
      {props.t(data.title)}
    </span>
  ));

  return (
    <>
      <section id="home" className="homeSection">
        <div className="content">
          <Row>
            <Col lg={8} md={7} sm={6}>
              <ROJLogo />
              <div className="description">
                <Trans i18nKey="sliderTitle">Buy or Sell</Trans>
                <span className="productType">
                  <TextLoop>{buyLoops}</TextLoop>
                </span>
                <Trans i18nKey="sliderTitle2">
                  products or services in 2 seconds!
                </Trans>
              </div>

              <div className="downloadContainer ml-auto">
                <div className="float-left">
                  <ROJDownloadButton
                    handleClick={() => {
                      logEvent(analytics, "playstore_btn_clicked");
                    }}
                    alt={props.t("playStore")}
                    link="https://play.google.com/store/apps/details?id=com.roojaa.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    iconURL="/images/google-play-badge.svg"
                  />
                </div>

                <div className="float-left buttonPaddingLeft">
                  <ROJDownloadButton
                    handleClick={() => {
                      logEvent(analytics, "appstore_btn_clicked");
                    }}
                    alt={props.t("appStore")}
                    link="https://apps.apple.com/app/id6461267662"
                    iconURL="/images/app-store-badge.svg"
                  />
                </div>
              </div>
            </Col>
            <Col lg={4} md={5} sm={6}>
              <div className="phone"></div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

ROJHomeSection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(ROJHomeSection);
