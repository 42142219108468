import React from "react";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ROJInfoBox from "../InfoBox";

const infoBoxData = [
  {
    title: "fast",
    text: "fastInfo",
    icon: "/icons/fast.svg"
  },
  {
    title: "simple",
    text: "simpleInfo",
    icon: "/icons/simple.svg"
  },
  {
    title: "secure",
    text: "secureInfo",
    icon: "/icons/secure.svg"
  }
];

const ROJAboutSection = props => {
  var infoBoxes = infoBoxData.map((info, index) => (
    <Col key={`infoBox${index}`} md={4}>
      <ROJInfoBox
        title={props.t(info.title)}
        text={props.t(info.text)}
        // linkText={props.t("seeMoreInfo")}
        iconURL={info.icon}
      />
    </Col>
  ));

  return (
    <>
      <section id="about" className="aboutSection">
        <div className="content">
          <div className="missionStatement">
            <Trans i18nKey="missionStatement">
              Increase Your Sales, Save Your Time
            </Trans>
          </div>
          <div className="hrContainer">
            <hr />
          </div>
          <Row className="infoBoxRow">{infoBoxes}</Row>
        </div>
      </section>
    </>
  );
};

ROJAboutSection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(ROJAboutSection);
