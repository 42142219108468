import React from "react";
import PropTypes from "prop-types";
import "./styles/ParagraphBox.css";

const ROJParagraphBox = props => {
  return (
    <>
      <div className="paragraphBox">
        {props.title ? (
          <div className="paragraphTitle">
            <span>{props.titleListNumber}</span>
            {props.title}
          </div>
        ) : (
          <></>
        )}
        {props.subtitle ? (
          <div className="paragraphSubTitle">{props.subtitle}</div>
        ) : (
          <></>
        )}
        <div className="paragraphText">{props.children}</div>
      </div>
    </>
  );
};

ROJParagraphBox.propTypes = {
  title: PropTypes.string,
  titleListNumber: PropTypes.string,
  subtitle: PropTypes.string,
  listNumber: PropTypes.string,
  children: PropTypes.string
};

export default ROJParagraphBox;
