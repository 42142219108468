import React, { Suspense } from "react";
import PropTypes from "prop-types";
import ROJFooterSection from "../components/FooterSection";
import ROJTermsSection from "../components/terms/TermsSection";
import "../i18n";

const ROJTerms = props => (
  <>
    <Suspense fallback={<div></div>}>
      {/* <HCHeader/> */}
      <div className="privacyContent">
        <ROJTermsSection />
        <ROJFooterSection />
      </div>
      {/* <HCFooter/> */}
    </Suspense>
  </>
);

ROJTerms.propTypes = {
  t: PropTypes.func
};

export default ROJTerms;
