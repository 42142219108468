import React, { Suspense } from "react";
import ROJHomeSection from "../components/home/HomeSection";
import ROJAboutSection from "../components/home/AboutSection";
import ROJFeatureSection from "../components/home/FeatureSection";
import ROJScreenshotSection from "../components/home/ScreenshotSection";
import ROJDownloadSection from "../components/home/DownloadSection";
import ROJFooterSection from "../components/FooterSection";
import ROJMenu from "../components/Menu";
import IframeResizer from "iframe-resizer-react";
import "../i18n";

const ROJHomePage = () => (
  <>
    <Suspense fallback={<div></div>}>
      {/* <HCHeader/> */}
      <div className="homeContent">
        <ROJMenu />
        <ROJHomeSection />
        <ROJAboutSection />
        <IframeResizer
          heightCalculationMethod="highestElement"
          log
          src="https://www.youtube.com/embed/sqUKgQdu8ds?si=75tP5ZpX29cXxdQV"
          style={{ width: "1px", minWidth: "100%", minHeight: "315px" }}
        />
        <ROJFeatureSection />
        <ROJScreenshotSection />
        <IframeResizer
          // heightCalculationMethod="highestElement"
          log
          src="https://www.youtube.com/embed/ult4MDmyRcc?si=-So_fbMeC7OwgnsW"
          style={{ width: "1px", minWidth: "100%", minHeight: "315px" }}
        />
        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/sqUKgQdu8ds?si=75tP5ZpX29cXxdQV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
        <ROJDownloadSection />
        <ROJFooterSection />
      </div>
      {/* <HCFooter/> */}
    </Suspense>
  </>
);

export default ROJHomePage;
