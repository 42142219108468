import React from "react";
import { Trans, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ROJDownloadButton } from "../Buttons";
import { analytics } from "../../App";
import { logEvent } from "firebase/analytics";

const ROJDownloadSection = props => {
  return (
    <>
      <section id="download" className="downloadSection">
        <div className="content">
          <Row>
            <Col md={6}>
              <div className="captionBox">
                <div className="title">{props.t("downloadSectionTitle")}</div>
                <div className="caption">
                  <Trans i18nKey="downloadSectionCaption">
                    Naira wahala? <br /> Transfer Wahala? <br /> No Wahala!{" "}
                    <br /> roojaa your sellers in 2 seconds! (^_^)
                  </Trans>
                </div>

                <div className="downloadContainer">
                  <div className="float-left">
                    <ROJDownloadButton
                      handleClick={() => {
                        logEvent(analytics, "playstore_btn_clicked");
                      }}
                      alt={props.t("playStore")}
                      link="https://play.google.com/store/apps/details?id=com.roojaa.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                      iconURL="/images/google-play-badge.svg"
                    />
                  </div>
                  <div
                    className="float-left buttonPaddingLeft"
                    style={{ marginLeft: "10px" }}
                  >
                    <ROJDownloadButton
                      handleClick={() => {
                        logEvent(analytics, "appstore_btn_clicked");
                      }}
                      alt={props.t("appStore")}
                      link="https://apps.apple.com/app/id6461267662"
                      iconURL="/images/app-store-badge.svg"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="phone"></div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

ROJDownloadSection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(ROJDownloadSection);
