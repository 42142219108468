import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ROJParagraphBox from "../ParagraphBox";
import ROJLogo from "../Logo";

const privacyTranslationData = [
  {
    title: "termsAcceptanceofTerms",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsAcceptanceofTerms1.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsAcceptanceofTerms1.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsAcceptanceofTerms1.3",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsAcceptanceofTerms1.4",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsServices",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsServices2.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsServices2.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsServices2.3",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsServices2.4",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsServices2.5",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsServices2.6",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsUserRegistrationAndAccounts",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsUserRegistrationAndAccounts3.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsUserRegistrationAndAccounts3.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsUserRegistrationAndAccounts3.3",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsPurchasesAndWalletBalance",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsPurchasesAndWalletBalance4.1",
          subListItems: [
            {
              text: "termsPurchasesAndWalletBalance4.1.1"
            },
            {
              text: "termsPurchasesAndWalletBalance4.1.2"
            },
            {
              text: "termsPurchasesAndWalletBalance4.1.3"
            }
          ]
        }
      },
      {
        item: {
          text: "termsPurchasesAndWalletBalance4.2",
          subListItems: [
            {
              text: "termsPurchasesAndWalletBalance4.2.1"
            },
            {
              text: "termsPurchasesAndWalletBalance4.2.2"
            },
            {
              text: "termsPurchasesAndWalletBalance4.2.3"
            }
          ]
        }
      }
    ]
  },
  {
    title: "termsFundings",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsFundings5.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsFundings5.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsFundings5.3",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsReferralProgram",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsReferralProgram6.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsReferralProgram6.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsReferralProgram6.3",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsRecordKeeping",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsRecordKeeping7.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsRecordKeeping7.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsRecordKeeping7.3",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsProhibitedConduct",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsProhibitedConduct8.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsProhibitedConduct8.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsProhibitedConduct8.3",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsProhibitedConduct8.4",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsWithdrawalPolicy",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsWithdrawalPolicy9.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsWithdrawalPolicy9.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsWithdrawalPolicy9.3",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsWithdrawalPolicy9.4",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsWithdrawalPolicy9.5",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsIntellectualProperty",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsIntellectualProperty10.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsIntellectualProperty10.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsIntellectualProperty10.3",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsIntellectualProperty10.4",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsIntellectualProperty10.5",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsDisclaimerOfWarranties",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsDisclaimerOfWarranties11.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsDisclaimerOfWarranties11.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsDisclaimerOfWarranties11.3",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsDisclaimerOfWarranties11.4",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsLimitationOfLiability",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsLimitationOfLiability12.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsLimitationOfLiability12.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsLimitationOfLiability12.3",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsGoverningLawAndJurisdiction",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsGoverningLawAndJurisdiction13.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsGoverningLawAndJurisdiction13.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsGoverningLawAndJurisdiction13.3",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsTermination",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsTermination14.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsTermination14.2",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsAssignment",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsAssignment15.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsAssignment15.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsAssignment15.3",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsEntireAgreement",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsEntireAgreement16.1",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsWaiverAndSeverability",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsWaiverAndSeverability17.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsWaiverAndSeverability17.2",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsModifications",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsModifications18.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsModifications18.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsModifications18.3",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsAdministrationOfVat",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsAdministrationOfVat19.1",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsAdministrationOfVat19.2",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsAdministrationOfVat19.3",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsAdministrationOfVat19.4",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsAdministrationOfVat19.5",
          subListItems: []
        }
      },
      {
        item: {
          text: "termsAdministrationOfVat19.6",
          subListItems: []
        }
      }
    ]
  },
  {
    title: "termsContactUs",
    subtitle: "",
    listItems: [
      {
        item: {
          text: "termsContactUs20.1",
          subListItems: []
        }
      }
    ]
  }
];

const ROJTermsSection = props => {
  var privacyItems = privacyTranslationData.map((data, titleIndex) => {
    titleIndex = titleIndex + 1;
    return (
      <ROJParagraphBox
        key={`${titleIndex}. ROJParagraphBox`}
        titleListNumber={`${titleIndex}. `}
        title={props.t(data.title)}
        subtitle={props.t(data.subtitle)}
      >
        <ul key={`${titleIndex}privacydatalist`}>
          {data.listItems.map((item, listItemsIndex) => {
            listItemsIndex = listItemsIndex + 1;
            return (
              <li key={`${listItemsIndex}privacyitemlist`}>
                <div>
                  <span className="numberText">
                    {titleIndex + "." + listItemsIndex}
                  </span>
                  {props.t(`${item.item.text}`)}
                  <div>
                    <ul key={`${listItemsIndex}privacysubitemlist`}>
                      {item.item.subListItems.map((subitem, index) => {
                        index = index + 1;
                        return (
                          <li key={`${index}privacysubsubitemlist`}>
                            <div>
                              <span className="numberText">{`${titleIndex}.${listItemsIndex}.${index}`}</span>
                              {props.t(`${subitem.text}`)}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </ROJParagraphBox>
    );
  });

  return (
    <>
      <ROJLogo />

      <div className="content">
        <div className="pageTitle">{props.t("termsOfService")}</div>
        <ROJParagraphBox>{props.t("termsEffectiveDate")}</ROJParagraphBox>
        <ROJParagraphBox>{props.t("termsIntro1")}</ROJParagraphBox>
        <ROJParagraphBox>{props.t("termsIntro2")}</ROJParagraphBox>
        <ROJParagraphBox>{props.t("termsIntro3")}</ROJParagraphBox>
        <ROJParagraphBox>{props.t("termsIntro4")}</ROJParagraphBox>
        <ROJParagraphBox>{props.t("termsIntro5")}</ROJParagraphBox>
        {privacyItems}
        <ROJParagraphBox>{props.t("termsLastUpdated")}</ROJParagraphBox>
      </div>
    </>
  );
};

ROJTermsSection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(ROJTermsSection);
