import React, { Suspense } from "react";
import PropTypes from "prop-types";
import ROJFooterSection from "../components/FooterSection";
import ROJPrivacyPolicySection from "../components/privacy_policy/PrivacyPolicySection";
import "../i18n";

const ROJPrivacyPolicy = props => (
  <>
    <Suspense fallback={<div></div>}>
      {/* <HCHeader/> */}
      <div className="privacyContent">
        <ROJPrivacyPolicySection />
        <ROJFooterSection />
      </div>
      {/* <HCFooter/> */}
    </Suspense>
  </>
);

ROJPrivacyPolicy.propTypes = {
  t: PropTypes.func
};

export default ROJPrivacyPolicy;
