import React from "react";
import PropTypes from "prop-types";
import "./styles/FeatureBox.css";

const ROJFeatureBox = props => {
  const feature = (
    <div className="featureShadowBox">
      <div className="featureBox">
        <div className="textBox">
          <div className="title">{props.title}</div>
          <div className="desc">{props.description}</div>
        </div>
        <div className="featureIconBox">
          <div
            className="featureIcon"
            style={{ backgroundImage: `url('${props.iconURL}')` }}
          ></div>
        </div>
      </div>
    </div>
  );

  const rightFeature = (
    <div className="featureShadowBoxRight">
      <div className="featureBox">
        <div className="featureIconBox">
          <div
            className="featureIcon"
            style={{ backgroundImage: `url('${props.iconURL}')` }}
          ></div>
        </div>
        <div className="textBox">
          <div className="title">{props.title}</div>
          <div className="desc">{props.description}</div>
        </div>
      </div>
    </div>
  );

  return <>{props.isPositionRight ? rightFeature : feature}</>;
};

ROJFeatureBox.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  linkText: PropTypes.string,
  isPositionRight: PropTypes.bool,
  iconURL: PropTypes.string
};

export default ROJFeatureBox;
