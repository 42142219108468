import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ROJParagraphBox from "../ParagraphBox";
import ROJLogo from "../Logo";

const rewardTermsTranslationData = [
  {
    text: "rewardTerms1",
  },
  {
    text: "rewardTerms2",
  },
  {
    text: "rewardTerms3",
  },
  {
    text: "rewardTerms4",
  },
  {
    text: "rewardTerms5",
  },
  {
    text: "rewardTerms6",
  },
  {
    text: "rewardTerms7",
  },
  {
    text: "rewardTerms8",
  },
  {
    text: "rewardTerms9",
  },
  {
    text: "rewardTerms10",
  },
  {
    text: "rewardTerms11",
  },
  {
    text: "rewardTerms12",
  },
  {
    text: "rewardTerms13",
  },
  {
    text: "rewardTerms14",
  },
  {
    text: "rewardTerms15",
  },
  {
    text: "rewardTerms16",
  },
  {
    text: "rewardTerms17",
  },
  {
    text: "rewardTerms18",
  },
  {
    text: "rewardTerms19",
  },
  {
    text: "rewardTerms20",
  },
  {
    text: "rewardTerms21",
  },
  {
    text: "rewardTerms22",
  },
  {
    text: "rewardTerms23",
  }
];

const ROJRewardTermsSection = props => {

    

  return (
    <>
      <ROJLogo />

      <div className="content">
        <div className="pageTitle">{props.t("rewardTermsConditions")}</div>
        <ROJParagraphBox>{props.t("rewardTermsEffectiveDate")}</ROJParagraphBox>
        <ol key={`rewarddatalist`}>
          {rewardTermsTranslationData.map((item, listItemsIndex) => {
            return (
              <li key={`${listItemsIndex}rewarditemlist`}>
                <div className="paragraphText">
                  {props.t(`${item.text}`)}
                </div>
              </li>
            );
          })}
        </ol>
        <ROJParagraphBox>{props.t("rewardTermsLastUpdated")}</ROJParagraphBox>
      </div>
    </>
  );
};

ROJRewardTermsSection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(ROJRewardTermsSection);
