import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ROJFooterSection = props => {
  return (
    <>
      <footer id="contact">
        <div className="content">
          <div className="links">
            <Row>
              <Col>
                <a href="/legal/privacy">
                  <small>{props.t("privacyPolicy")}</small>
                </a>
              </Col>
              <Col>
                <a href="#blog">
                  <small>{props.t("blog")}</small>
                </a>
              </Col>
              <Col>
                <a href="/legal/terms">
                  <small>{props.t("termsOfService")}</small>
                </a>
              </Col>
              <Col>
                <a href="/legal/reward-terms">
                  <small>{props.t("rewardTCs")}</small>
                </a>
              </Col>
            </Row>
          </div>
          <div className="contact">
            <div>
              <span className="hello">{props.t("sayHello")}</span>
              <span className="marginLeft20">
                <a
                  href="mailto:hello@roojaa.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  hello@roojaa.com
                </a>
              </span>
            </div>
            <div className="text-center">
              <span className="marginLeft20 social">
                <a
                  href="https://twitter.com/roojaa_app"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="lni lni-twitter"></i>
                </a>
              </span>
              <span className="marginLeft20 social">
                <a
                  href="https://instagram.com/roojaa_app"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="lni lni-instagram"></i>
                </a>
              </span>
              <span className="marginLeft20 social">
                <a
                  href="https://www.facebook.com/61551666943776"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="lni lni-facebook"></i>
                </a>
              </span>
            </div>
          </div>
          <div className="layout">
            <small className="copyright">{props.t("copyright")}</small>
          </div>
        </div>
      </footer>
    </>
  );
};

ROJFooterSection.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(ROJFooterSection);
