import React from "react";
// import PublicRoute from "./components/PublicRoute";
import { Routes, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import ROJHomePage from "./pages/Home";
import ROJPrivacyPolicy from "./pages/PrivacyPolicy";
import ROJTerms from "./pages/Terms";
import ROJRewardTerms from "./pages/RewardTerms";

/**
 * @typedef {[{ path: string, component: JSX }]} publicRoutes
 * The public routes of this application.
 */

const publicRoutes = [
  { key: "home", path: "/", component: <ROJHomePage /> },
  { key: "privacy", path: "/legal/privacy", component: <ROJPrivacyPolicy /> },
  { key: "terms", path: "/legal/terms", component: < ROJTerms /> },
  { key: "reward_terms", path: "/legal/reward-terms", component: < ROJRewardTerms /> }
];  

// web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBCn5akjkb0cQeZkWsIlG8drs_YKrHCkLk",
  authDomain: "roojaa-me.firebaseapp.com",
  projectId: "roojaa-me",
  storageBucket: "roojaa-me.appspot.com",
  messagingSenderId: "379235297046",
  appId: "1:379235297046:web:6432f2a45a6db45aca9237",
  measurementId: "G-3SNCDHLJ1Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

class RoojaaWeb extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <React.Fragment>
        <Routes>
          {publicRoutes.map(route => (
            <Route key={route.key} exact path={route.path} element={route.component} />
            // <PublicRoute
            //   path={route.path}
            //   component={route.component}
            //   key={route.key}
            // />
          ))}

          <Route redirect={this.redirect} component={ROJHomePage} />
        </Routes>
      </React.Fragment>
    );
  }
}

export default RoojaaWeb;
